import {
  type IUploadDialog,
  type IAlertDialog,
  type IConfirmDialog,
  type ISnackbar
} from '../interfaces/index'

export const uploadDialogTemplate = (dialog: IUploadDialog) => {
  return `<div class="avv_dialog--upload font-ds min-w-96 max-w-lg mx-8 p-5 bg-white rounded-lg flex flex-col justify-center text-center shadow-modal">
            ${
              dialog.uploadTitle === null
                ? ''
                : `<h2 class="text-tertiary font-semibold text-xl">${dialog.uploadTitle}</h2>`
            }
            <div class="py-5">
              <p class="text-tertiary text-sm">${dialog.uploadDescription}</p>
            </div>
            <button type="button" class="upload" aria-label="Upload a file" tabindex="1">
              <p>
                <i class="material-icons" aria-hidden="true">cloud_upload</i>
                <span>Drag and drop a file or click here to upload.</span>
              </p>
            </button>
            <button type="button" class="avv-button secondary cancel">Cancel</button>
          </div>`
}

export const alertDialogTemplate = (dialog: IAlertDialog) => {
  return `<div class="avv_dialog--alert font-ds min-w-96 max-w-lg mx-8 p-5 bg-white rounded-lg flex flex-col justify-center text-center shadow-modal">
            ${
              dialog.alertTitle === null
                ? ''
                : `<h2 class="text-tertiary font-semibold text-xl">${dialog.alertTitle}</h2>`
            }
            <div class="py-5">
              <p class="text-tertiary text-sm">${dialog.alertMessage}</p>
            </div>
            ${
              dialog.alertButton === null
                ? ''
                : `<div class="flex justify-end gap-2.5 mt-2.5"><button type="button" class="avv-button text-center font-medium text-sm px-4 py-2 border border-1_5 rounded-lg border-transparent bg-primary hover:bg-primary-80 text-primary-10 shadow-button focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-primary">${dialog.alertButton}</button></div>`
            }
          </div>`
}

export const snackbarDialogTemplate = (dialog: ISnackbar) => {
  return `<div class="avv_dialog--snackbar">
            <p role="status" class="snack-message">${dialog.snackMessage}</p>
          </div>`
}

export const confirmDialogTemplate = (dialog: IConfirmDialog) => {
  const dynClasses = {
    warnTitle: dialog.warn ? 'avv-danger-color' : 'text-tertiary',
    message: dialog.squareDisplay ? 'text-center my-5' : '',
    warnButton: dialog.warn ? 'danger' : '',
    square: dialog.squareDisplay
      ? 'square flex flex-col items-center bg-white p-12 max-w-[50%] lg:max-w-[33%] rounded-md'
      : '',
    textCenter: dialog.squareDisplay ? 'text-center' : ''
  }

  const dynTexts = {
    title: dialog.confirmTitle || localizeText('general.confirm_action'),
    confirm: dialog.okButtonText ? dialog.okButtonText : 'OK',
    cancel: dialog.cancelButtonText
      ? dialog.cancelButtonText
      : localizeText('general.cancel')
  }

  const dynElements = {
    closeButton: dialog.closeButton
      ? `<i title="Abort the confirmation dialog" style="float:right;" class="material-icons close cursor-pointer">close</i>`
      : '',
    inputLabel: dialog.inputLabel
      ? `<div class="flex flex-col gap-1 mt-2.5"><textarea class="bg-white text-sm text-tertiary rounded-lg border border-secondary-40 focus-within:border-tertiary-80 w-full focus:text-tertiary placeholder:italic placeholder:text-secondary-80" placeholder="${dialog.inputLabel}"></textarea></div>`
      : ''
  }

  return `<div class="font-ds min-w-96 max-w-lg mx-8 p-5 bg-white rounded-lg flex flex-col justify-center text-center shadow-modal">
            ${`<h2 class="semibold text-xl ${dynClasses.warnTitle}">${dynTexts.title}${dynElements.closeButton}</h2>`}
            <div class="py-5">
              <p class="text-tertiary text-sm">${dialog.confirmMessage}</p>
              ${dynElements.inputLabel}
            </div>
            <div class="flex justify-end gap-2.5 mt-2.5">
              <button type="button" class="text-center font-medium text-sm px-4 py-2 border border-1_5 rounded-lg border-primary-80 text-primary-80 hover:bg-primary-30 focus-visible:border-transparent focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-1 focus-visible:outline-primary no">${
                dynTexts.cancel
              }</button>
              <button type="button" class="text-center font-medium text-sm px-4 py-2 border border-1_5 rounded-lg border-transparent bg-primary hover:bg-primary-80 text-primary-10 shadow-button focus-visible:outline focus-visible:outline-1 focus-visible:outline-offset-2 focus-visible:outline-primary ${
                dynClasses.warnButton
              } yes">${dynTexts.confirm}</button>
            </div>
          </div>`
}
